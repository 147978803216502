import {useEffect, useContext} from "react";
import {useParams} from "react-router-dom";
import {isBefore, isAfter} from "date-fns";
import {IWebinar} from "@plumeuk/shapeshift-types";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {useSetModuleCompletion} from "@plumeuk/shapeshift-common/hooks";


interface IWebinarView { webinar: IWebinar | undefined }

export const WebinarView = ({webinar}: IWebinarView): JSX.Element => {
	const {moduleSlug: webinarSlug, courseSlug} = useParams();
	const {setComplete, apiResult} = useSetModuleCompletion()
	const {curriculumDispatch} = useContext(CurriculumContext);

	useEffect(() => {
		if( apiResult && apiResult.statusCode === 200) {
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "webinar", slug: webinarSlug as string}, status: true
			});
		}
	}, [apiResult]);

	useEffect(() => {
		if ( webinar && courseSlug && webinarSlug && isAfter(new Date(), webinar.startDateTime) && isBefore(new Date(), webinar.endDateTime)) {
			setComplete(courseSlug, webinarSlug, "webinar", true);
		}
	}, [webinar]);

	return (
		<iframe style={{border: 0, height: "700px", width: "100%"}} src="https://cohd.lexgo.live/c/cohd" allow="camera; microphone" />
	);
}