import {CourseSideMenuContent as CommonCourseSideMenuContent, CourseSideMenuModule} from "@plumeuk/shapeshift-common/course";
import {moduleSlim, moduleTypeSlim} from "@plumeuk/shapeshift-types/ICourse";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import {plumePalette, transitionTime} from "../../constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import {compareAsc, isDate, getDueDateString} from "@plumeuk/shapeshift-common/common";
import {Box} from "@mui/material";


const useStyles = makeStyles()(() => ({
	sideMenuContent: {
		overflowX: "hidden",
		padding: "32px 24px 32px 40px",
		scrollbarGutter: "stable",
		"& [class*='progressBar']": {
			backgroundColor: plumePalette.transparentGrey
		},
		"& [class*='courseSideMenuModule']": {
			marginLeft: "-20px",
			marginRight: "-14px",
			"& [class*='contentContainer']": {
				position: "relative",
				borderRadius: "4px"
			},
			"&:nth-last-child(1)": {
				marginBottom: "8px"
			}
		},
		"& [class*='percentLabel']": {
			fontSize: "16px",
			fontWeight: 400
		},
		"& [class*='courseTitle']": {
			fontSize: "19px",
			lineHeight: "24px",
			marginBottom: "10px",
			fontWeight: 500
		},
		"& [class*='-title']": {
			fontSize: "15px"
		},
		"& .section > [class*='contentContainer']": {
			padding: 0
		},
		"& [class*='icon']": {
			overflow: "visible",
			width: "40px"
		},
		"& [class*='MuiSvgIcon']": {
			opacity: "1",
			width: "10px",
			height: "10px",
			backgroundColor: plumePalette.Blue,
			borderRadius: "100%",
			padding: "4px",
			overflow: "visible",
			marginTop: "-2px",
			marginRight: "8px",
			marginLeft: "2px"
		},
		"& [class*='expandIcon']": {
			opacity: "1",
			width: "24px",
			height: "24px",
			backgroundColor: "transparent",
			padding: "0px",
			marginTop: "0px",
			marginRight: "42px",
			marginLeft: 0,
			"& path": {
				fill: plumePalette.Grey
			}
		},
		"& [class*='contentContainer']": {
			margin: "2px 0px",
			padding: "10px 18px 7px",
			border: "2px solid transparent",
			transition: transitionTime,
			position: "relative",
			"& [class*='MuiSvgIcon']:not([class*='expandIcon'])": {
				position: "absolute",
				top: "50%",
				marginTop: "0px",
				left: "14px",
				transform: "translateY(-50%)"
			},
			"&:hover": {
				opacity: "0.7",
				transition: transitionTime
			},
			"& [class*='-icon']": {
				transform: "none",
				"& svg": {
					opacity: "0.4",
					"&[class*='-checkIcon']": {
						opacity: "1"
					}
				}
			}
		},
		"& [class*='isSection'] > [class*='contentContainer']:first-child [class*='-title']": {
			backgroundColor: plumePalette.light,
			padding: "17px 38px 13px",
			fontSize: "18px",
			fontWeight: "500",
			margin: "12px 0px 5px -24px",
			minWidth: "calc(100% + 24px)",
			borderTop: "1px solid " + plumePalette.Grey
		},
		"& [class*='isActive']": {
			"> [class*='contentContainer']": {
				border: "2px solid" + plumePalette.Blue,
				transition: transitionTime,
				"& [class*='MuiSvgIcon']:not([class*='expandIcon'])": {
					backgroundColor: plumePalette.Blue,
					position: "absolute",
					borderRadius: "100%",
					opacity: "0.6",
					width: "14px",
					height: "14px",
					transition: "0s",
					"&[class*='-checkIcon']": {
						backgroundColor: plumePalette.success,
						opacity: "1"
					}
				}
			}
		},
		"& [class*='hasChildren'] [class*='lesson']": {
			"& [class*='expandIcon']": {
				marginRight: "0px",
				position: "absolute",
				right: "12px",
				top: "50%",
				marginTop: "0px",
				transform: "translateY(-50%)"
			}
		},
		"& [class*='-checkIcon']": {
			backgroundColor: plumePalette.success,
			opacity: "1",
			width: "14px",
			height: "14px",
			marginLeft: 0
		},
		"& [class*='modulesParentContainer']": {
			opacity: 0,
			transition: transitionTime,
			paddingLeft: "20px",
			paddingRight: "20px"
		},
		"& [class*='isOpen']": {
			"& [class*='modulesParentContainer']": {
				opacity: 1,
				transition: transitionTime
			}
		},
		"& [class*='courseProgressionSummary']": {
			marginBottom: "14px"
		}
	},
	moduleIcon: {
		backgroundColor: "transparent !important",
		width: "25px !important",
		position: "absolute",
		left: "13px",
		top: "5px",
		height: "25px !important",
		fontSize: "20px  !important",
		"& path": {
			fill: plumePalette.Blue
		}
	},
	tooltipText: {
		fontSize: "14px",
		opacity: "0.7",
		marginTop: "5px"
	},
	moduleTooltip: {
		display: "none !important"
	},
	moduleTitle: {
		fontSize: "15px"
	}
}));

export const CourseSideMenuContent: FC = () => {
	const pageIsModule = useMatch("/course/:courseSlug/:moduleType/:moduleSlug");
	const moduleSlug = pageIsModule?.params.moduleSlug;
	const moduleType = pageIsModule?.params.moduleType;
	const {notify} = useContext(NotificationContext);
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const {curriculumState, curriculumDispatch} = useContext(CurriculumContext);
	const [activeModule, setActiveModule] = useState<moduleSlim>();
	const {classes} = useStyles();

	useEffect(() => {
		if (moduleSlug && moduleType)
			setActiveModule({slug: moduleSlug, type: moduleType as moduleTypeSlim});
	}, [moduleSlug, moduleType]);

	const handleActiveModuleCallback = useCallback((module: ICourseCurriculumModule): void => {
		navigate(`/course/${courseSlug}/${module.type}/${module.slug}`);//, {replace: !pageIsModule}
	}, [navigate]);

	const handleSetModuleComplete = useCallback((module: moduleSlim, status: boolean): void => {
		curriculumDispatch({type: "setModuleComplete", module, status})
	}, [curriculumDispatch]);

	const handleModuleForbidden = useCallback((moduleRequest: ICourseCurriculumModule, nextModule?: ICourseCurriculumModule): void => {
		notify(`Modules must be completed in sequence. Next up is ${nextModule?.title}`, "Hey now, slow down!", INotificationSeverity.warning, 5000);
	}, [curriculumDispatch]);

	const header = (m: ICourseCurriculumModule): JSX.Element => <>
		{
			m.title === "Onboarding information" && m.progression === false &&
			<InfoOutlinedIcon className={classes.moduleIcon} />
		}
		{
			m.title === "Get help" && m.progression === false &&
			<HelpCenterOutlinedIcon className={classes.moduleIcon} />
		}
	</>

	const getFooterText = useCallback((e: ICourseCurriculumModule) => {

		const currentDate = new Date();
		const fromDate = e.availableFrom ? new Date(e.availableFrom) : null;
		const dueDate = e.dueDate ? new Date(e.dueDate) : null;
		const isLocked = !!(fromDate && isDate(fromDate) && compareAsc(fromDate, currentDate) > 0);
		const isDue = !!(dueDate && isDate(dueDate) && compareAsc(dueDate, currentDate) > 0);
		const isOverdue = !!(dueDate && isDate(dueDate) && compareAsc(dueDate, currentDate) < 0);
		const isComplete = e.complete || false;

		let tooltipContent = "";
		const monthNames = ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"];

		if(isLocked && fromDate) {
			tooltipContent = `Available on ${fromDate.getDate()} ${monthNames[fromDate.getMonth()]} ${fromDate.getFullYear()}`;
		} else if(isComplete) {
			tooltipContent = "Completed";
		} else if(isDue && dueDate && isDate(dueDate)) {
			tooltipContent = `Due ${getDueDateString(dueDate)}`;
		} else if(isOverdue) {
			tooltipContent = "Overdue";
		}

		return tooltipContent;

	}, []);

	return (
		<CommonCourseSideMenuContent
			className={classes.sideMenuContent}
			data={curriculumState}
			courseSlug={courseSlug}
			onActiveChange={handleActiveModuleCallback}
			onModuleComplete={handleSetModuleComplete}
			onModuleForbidden={handleModuleForbidden}
			activeModule={activeModule}
			disableTooltip
			componentOverrides={{
				module: (p) => <CourseSideMenuModule {...p} componentOverrides={e => ({
					footer: <Box className={classes.tooltipText}>{getFooterText(e)}</Box>,
					header: header(e)
				})}/>
			}}
		/>
	);
}
