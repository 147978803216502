import {IUser as SSUser} from "@plumeuk/shapeshift-types";

export type IEditUserRequest = Omit<IUser, "blocked" | "provider" | "confirmed" | "blocked" | "createdAt" | "updatedAt"> ;

export type IEditUserResponse = IEditUserRequest

export const TEditUserRequest: IEditUserRequest = {
	firstname: "",
	lastname: "",
	email: "",
	username: ""
}

export interface IUser extends SSUser {
	firstname: string;
	lastname: string;
	email: string;
	username: string;
	provider: string;
	confirmed: boolean;
	blocked: boolean;
}

export const isUserValid = (user: IUser): boolean => (!!user.lastname && !!user.firstname);