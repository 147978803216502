import {Box, BoxProps} from "@mui/material";
import React, {useState, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {plumePalette} from "../../constants";

interface IProps extends BoxProps {
	targetDate: Date;
}

const useStyles = makeStyles()((theme) => ({
	countdownBanner: {
		width: "100%",
		background: theme.palette.primary.main,
		color: plumePalette.light
	}
}));

const Countdown: React.FC<IProps> = ({targetDate, className, ...props}) => {
	const {cx, classes} = useStyles();
	const [countdown, setCountdown] = useState<{days: number, hours: number, minutes: number, seconds: number}>();

	useEffect(() => {
		const intervalId = setInterval(() => {
			const now = new Date().getTime();
			const timeDifference = targetDate.getTime() - now;

			if (timeDifference <= 0) {
				clearInterval(intervalId);
				setCountdown({
					days: 0,
					hours: 0,
					minutes: 0,
					seconds: 0
				});
			} else {
				const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
				const hours = Math.floor(
					(timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				const minutes = Math.floor(
					(timeDifference % (1000 * 60 * 60)) / (1000 * 60)
				);
				const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

				setCountdown({
					days,
					hours,
					minutes,
					seconds
				});
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [targetDate]);

	return (
		<Box className={cx(classes.countdownBanner, className)} {...props}>
			{countdown && <>Starting in: {countdown.days} Days, {countdown.hours} Hours, {countdown.minutes} Minutes, {countdown.seconds} Seconds</>}
		</Box>
	);
};

export default Countdown;
