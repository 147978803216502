export const sideMenuWidth = "400px";
export const toolBarHeight = "95px";
export const transitionTime = "0.35s";

export const plumePalette = {
	light: "#EFECE7",
	lightGrey: "#CFD8DC",
	midGrey: "#BEBEBE",
	Grey: "#202020",
	darkGrey: "#2A2A2F",
	transparentGrey: "#20202010",
	Blue: "#3E75E6",
	background: "#ffffff",
	black: "#000",
	error: "#FE4A49",
	success: "#00C853"
}