import {Box, Checkbox, FormControlLabel, LinearProgress, Tooltip, Typography} from "@mui/material";
import {SurveyQuestionListContainer as SSSurveyQuestionContainer} from "@plumeuk/shapeshift-common/survey";
import {ISurveyAnswer, ISurveyQuestion} from "@plumeuk/shapeshift-types/ISurvey";
import {FC, useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";

interface IProps {
	questions?: ISurveyQuestion[],
	onSubmit: (e: {answers: ISurveyAnswer[], sendToInstructor?: boolean}) => void,
	response?: ISurveyAnswer[],
	submitResponseResultId?: string
}

const useStyles = makeStyles()((theme) => ({
	surveyQuestionContainer: {
		"[class*='ratingQuestion']": {
			width: "100%",
			textAlign: "left",
			"[class*='optionContainer']": {
				width: "100%",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				gap: "8px",
				"[class*='starContainer']": {
					width: "100%",
					height: "30px",
					borderRadius: "8px",
					padding: "24px",
					border: `1px solid ${theme.palette.grey[300]}`,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					marginBottom: "16px",
					svg: {
						display: "none"
					},
					"&.value-selected": {
						color: theme.palette.secondary.main,
						borderColor: theme.palette.secondary.main
					},
					"[class*='indexValue']": {
						display: "inline-block",
						fontSize: "18px"
					}
				}
			}
		}
	},
	sendToInstructorCheckBoxContainer: {
		marginTop: "50px",
		width: "100%",
		display: "flex",
		justifyContent: "right"
	},
	sendToInstructorCheckBoxLabel: {
		paddingTop: "9px"
	}
}));

export const SurveyQuestionContainer: FC<IProps> = ({questions, onSubmit, response, submitResponseResultId}) => {
	const navigate = useNavigate();
	const {courseSlug, moduleSlug: surveySlug} = useParams();
	const {classes} = useStyles();
	const [sendToInstructor, setSendToInstructor] = useState(false);

	useEffect(() => {
		if(submitResponseResultId)
			navigate("../complete/" + submitResponseResultId)
	}, [submitResponseResultId])

	const handleSubmission = useCallback((answers: ISurveyAnswer[], sendToInstructor?: boolean) => {
		onSubmit({answers, sendToInstructor})
	}, []);

	if(!courseSlug || !surveySlug)
		return <LinearProgress />

	return (
		<>
			<SSSurveyQuestionContainer
				className={classes.surveyQuestionContainer}
				surveySlug={surveySlug}
				courseSlug={courseSlug}
				questions={questions}
				response={response}
				onSubmit={(e) => handleSubmission(e, sendToInstructor)}
			/>
			<Box className={classes.sendToInstructorCheckBoxContainer}>
				<Tooltip arrow title="All surveys can be viewed by instructors regardless, but checking this option to send a push notification to your instructor with your response attached.">
					<FormControlLabel control={<Checkbox value={sendToInstructor} onChange={e => setSendToInstructor(e.target.checked)}/>} label="Notify my instructor" />
				</Tooltip>
			</Box>
		</>
	);
}