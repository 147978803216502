import {FC, useContext} from "react";
import {INotificationSeverity as Severity} from "@plumeuk/shapeshift-identity";
import {NotificationContext} from "@plumeuk/shapeshift-identity";
import {PageBase} from "../../components/pageBase/pageBase";

export const SandboxPage: FC = () => {
	const {notify} = useContext(NotificationContext);

	const sendNotification = (status: string, severity: Severity, lifeTime: null | number = 5000): void =>
		notify("Test " + status + " notification", "Something has happened", severity, lifeTime)

	return (
		<PageBase>
			<div>
			This page is used to test functionality and will be removed prior to release
				<br /><br />
				Timeout (5s)
				<br />
				<div onClick={() => sendNotification("info", Severity.neutral)}>Click to test info notification</div>
				<br />
				<div onClick={() => sendNotification("warning", Severity.warning)}>Click to test warning notification</div>
				<br />
				<div onClick={() => sendNotification("success", Severity.success)}>Click to test success notification</div>
				<br />
				<div onClick={() => sendNotification("error", Severity.error)}>Click to test error notification</div>
				<br/>
				<div onClick={() => {
					sendNotification("info", Severity.neutral);
					sendNotification("warning", Severity.warning);
					sendNotification("success", Severity.success);
					sendNotification("error", Severity.error)
				}}>
					Click to test all notifications (x4)
				</div>
				<br />
				<div onClick={() => notify("This is a short message and it can span multiple lines if required", "Info", Severity.neutral, 15000)}>
					Click to test long body (15s)
				</div>
				<br />
				<div onClick={() => notify(null, "Info No Body", Severity.neutral, 120000)}>
					Click to no body (2min)
				</div>
				<br /><br />
				Permanent
				<br/>
				<div onClick={() => sendNotification("Permanent", Severity.neutral, null)}>Click to test permanent info notification (will not close)</div>
				<br />
				<div onClick={() => sendNotification("Permanent", Severity.warning, null)}>Click to test permanent warning notification (will not close)</div>
				<br />
				<div onClick={() => sendNotification("Permanent", Severity.success, null)}>Click to test permanent success notification (will not close)</div>
				<br />
				<div onClick={() => sendNotification("Permanent", Severity.error, null)}>Click to test permanent error notification (will not close)</div>
				<br />
				<br/>
				<br />
				<div onClick={() => {
					sendNotification("Permanent", Severity.neutral, null);
					sendNotification("Permanent", Severity.warning, null);
					sendNotification("Permanent", Severity.success, null);
					sendNotification("Permanent", Severity.error, null)
				}}>
					Click to test permanent all notifications (x4) (will not close)
				</div>
			</div>
		</PageBase>
	);

}