import {FC} from "react";
import {NavBar} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import {plumePalette} from "../constants";

type IProps = {
	onBurgerAction: () => void,
}

const useStyles = makeStyles()((theme) => ({
	moduleNavBar: {
		backgroundColor: "#fff",
		padding: "0 10px",
		[theme.breakpoints.down("sm")]:{
			padding: 0
		},
		"& svg": {
			color: plumePalette.Grey,
			border: "0px solid",
			width: "1.35em",
			height: "1.35em"
		}
	},
	burgerIcon: {
		padding: "3px",
		fontSize: "20px",
		borderRadius: "100px"
	}
}));

export const ModuleNavBar: FC<IProps> = ({onBurgerAction, ...props}) => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const handleOnClose = ():void => navigate("/my-learning");

	return (
		<NavBar className={classes.moduleNavBar} {...props}>
			<MenuIcon className={classes.burgerIcon} onClick={() => onBurgerAction()}/>
			<CloseIcon onClick={() => handleOnClose()}/>
		</NavBar>
	);
};

export default NavBar;