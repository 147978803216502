import {QuizQuestionListContainer as SSQuizQuestionContainer} from "@plumeuk/shapeshift-common/quiz";
import {IQuizAnswer, IQuizQuestion} from "@plumeuk/shapeshift-types";
import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "tss-react/mui";

interface IProps {
	questions?: IQuizQuestion[],
	onSubmit: (answers: IQuizAnswer[]) => void,
	submitResponseResultId?: string,
}

const useStyles = makeStyles()((theme) => ({
	questionContainer: {
		"& [ class*='questionHeader']": {
			display: "flex",
			justifyContent: "space-between"
		}
	}
}));

export const QuizQuestionContainer: FC<IProps> = ({questions, onSubmit, submitResponseResultId}) => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	useEffect(() => {
		if(submitResponseResultId)
			navigate("../complete/" + submitResponseResultId)
	}, [submitResponseResultId])

	return (
		<SSQuizQuestionContainer
			className={classes.questionContainer}
			questions={questions}
			onSubmit={answers => {onSubmit(answers)}}
		/>
	);
}