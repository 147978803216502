import {VideoPlayer as SSVideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {useState} from "react";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {ReactPlayerProps} from "react-player";
import {forwardRef} from "react";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";

const useStyles = makeStyles()((theme) => ({
	videoPlayerBackdrop: {
		background: "black",
		width: "100%",
		boxSizing: "border-box",
		left: 0,
		top: 0,
		right: "0px",
		position: "absolute",
		height: "540px",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		}
	},
	videoPlayer: {
		zIndex: 5,
		background: "black",
		position: "absolute",
		opacity: 0,
		left: 0,
		transition: "opacity .5s ease-in",
		right: "0px"
	},
	videoPlayerSpacer: {
		height: "465px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			height: "280px"
		}
	}
}));

interface IPropsCustom {
	videoUrl?: string,
}

export type IProps = Omit<ReactPlayerProps, "url"> & IPropsCustom;

export const VideoPlayer = forwardRef<VideoPlayerRef, IProps>(function VideoPlayer({videoUrl}, ref) {

	const {classes} = useStyles();
	const mq = useMq()
	if (!videoUrl) return <></>

	const [videoReady, setVideoReady] = useState(false);

	return (
		<>
			<div className={classes.videoPlayerBackdrop}>
				<SSVideoPlayer
					ref={ref}
					onReady={() => setVideoReady(true)}
					className={classes.videoPlayer}
					style={{opacity: videoReady ? 1 : 0}}
					url={videoUrl}
					controls={true}
					height={mq.mobile ? "250px" : "540px"}
				/>
			</div>
			<div className={classes.videoPlayerSpacer} />
		</>
	);
});