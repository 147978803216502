import {FC, useCallback, useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../../components/pageBase/centeredContainer";
import {SurveyQuestionContainer} from "./SurveyQuestion/SurveyQuestionContainer";
import {SurveyQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {LinearProgress} from "@mui/material";
import {CurriculumContext} from "../../../../contexts/curriculumContext";
import {ISurveyAnswer, ISurveyQuestion} from "@plumeuk/shapeshift-types";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";

import {plumePalette} from "../../../../constants";
import {useApi} from "@plumeuk/shapeshift-identity";
import {useSurvey} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		marginTop: "40px",
		textAlign: "left",
		"& [class*='questionContainer']":{
			"& h3": {
				fontStyle: "normal",
				fontWeight: 800,
				fontSize: "26px",
				lineHeight: "30px",
				textAlign: "left",
				[theme.breakpoints.down("sm")]: {
					marginTop: "7px"
				}
			}
		},
		"& [class*='questionHint'] [class*='bodyContainer']": {
			textAlign: "left"
		},
		"& [class*='optionContainer'] [class*='questionOption']": {
			borderRadius: "78px",
			borderWidth: "1px",
			"& [class*='optionId']": {
				fontWeight: 800,
				borderWidth: "1px"
			}
		},
		"& [class*='questionContainer']:not(:first-child)": {
			marginTop: "46px"
		},
		"& [class*='questionCounter']": {
			color: "#7A9097",
			fontSize: "16px",
			fontWeight: "700",
			lineHeight: "35px",
			textTransform: "uppercase",
			[theme.breakpoints.down("sm")]: {
				fontSize:  "14px"
			}
		},
		"& [class*='answerCounter']": {
			fontSize: "15px",
			fontWeight: "600",
			lineHeight: "18px",
			padding: "11px 20px",
			textAlign:  "center",
			background: plumePalette.lightGrey,
			color: plumePalette.Grey,
			[theme.breakpoints.down("sm")]: {
				fontSize:  "14px",
				padding: "10px 16px"
			}
		},
		"[class*='starLabel']": {
			marginTop: "8px",
			marginBottom: "8px"
		}
	},
	title: {
		marginBottom: "18px",
		"& h2": {
			fontSize: "58px"
		},
		"& h5": {
			fontSize: "24px",
			lineHeight: "46px",
			marginTop: "0px !important"
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: "10px",
			"& h2": {
				fontSize: "42px"
			},
			"& h5": {
				fontSize: "20px",
				marginTop: "-10px !important"
			}
		}
	},
	content: {
		marginBottom: "10px",
		paddingBottom: "20px",
		borderBottom: `${plumePalette.lightGrey} solid 1px`,
		"& *:first-child": {
			marginTop: "0px",
			paddingTop: "0px"
		},
		"& p": {
			color: plumePalette.Grey,
			fontSize: "18px",
			fontWeight: 400,
			lineHeight: "160%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "17px"
			}
		}
	}
}));

interface IProps {
	courseSlug?: string,
	surveySlug?: string,
	submitResponseActionOverride?: (e: {sendToInstructor?: boolean, answers: ISurveyAnswer[]}) => true,
	surveyQuestionDataOverride?: ISurveyQuestion[],
	surveyResponseDataOverride?: ISurveyAnswer[]
}


export const SurveyQuestions: FC<IProps> = (props) => {
	const {classes} = useStyles();
	const params = useParams();
	const courseSlug = props.courseSlug ?? params.courseSlug;
	const surveySlug = props.surveySlug ?? params.moduleSlug;
	const {survey} = props.surveyResponseDataOverride ? {survey: {title: "", subtitle: "", content: "", response: props.surveyResponseDataOverride}} : useSurvey(courseSlug, surveySlug)

	const navigate = useNavigate();
	const {curriculumDispatch} = useContext(CurriculumContext);
	const [surveyResponse, postSurvey] = useApi<ISurveyAnswer[], {answers: ISurveyAnswer[], sendToInstructor?: boolean}>();

	const submitResponse = useCallback((courseSlug: string, surveySlug: string, response: {answers: ISurveyAnswer[], sendToInstructor?: boolean}): void => {

		postSurvey({
			url: `/api/survey/${courseSlug}/${surveySlug}`,
			data: response,
			method: "POST"
		})
	}, [])

	useEffect(() => {
		if(!surveyResponse.data || !surveySlug)
			return;

		curriculumDispatch({
			type: "setModuleComplete", module: {type: "survey", slug: surveySlug}, status: true})

		navigate("../complete")
	}, [surveyResponse, surveySlug]);

	if(!courseSlug || !surveySlug) return <LinearProgress />

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<PageTitle className={classes.title} title={survey?.title} subtitle={survey?.subtitle} />
			<WYSIWYG className={classes.content}>
				{survey?.content ?? ""}
			</WYSIWYG>
			<SurveyQuestionsDataProvider courseSlug={courseSlug} surveySlug={surveySlug} data={props.surveyQuestionDataOverride}>
				{({surveyQuestions}) =>
					<SurveyQuestionContainer
						questions={surveyQuestions}
						response={survey?.response}
						onSubmit={(e) => props.submitResponseActionOverride ? props.submitResponseActionOverride(e) : submitResponse(courseSlug, surveySlug, e)}
					/>
				}
			</SurveyQuestionsDataProvider>
		</CenteredContainer>
	);
}