import {FC, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {WebinarDataProvider} from "@plumeuk/shapeshift-common/providers";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {isBefore, isAfter} from "date-fns";
import {ModuleTab, ModuleTabs, ModuleNotAvailable} from "@plumeuk/shapeshift-common/module";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {NotesSection} from "@plumeuk/shapeshift-common/notes";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {Files} from "@plumeuk/shapeshift-common/files";
import {Box, Button, Typography} from "@mui/material";
import {WebinarView} from "./WebinarView";
import {VideoPlayer} from "../../../components/VideoPlayer/VideoPlayer";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {plumePalette, transitionTime} from "../../../constants";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {getNextLinearModule} from "@plumeuk/shapeshift-common/common";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import Countdown from "../../../components/countDown/countDown";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";

const useStyles = makeStyles()((theme) => ({
	webinarPage: {
		paddingTop: "50px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		},
		"& [class*='recentFilter']": {
			marginLeft: "0px",
			borderRadius: "10px",
			border: "0px solid !important"
		},
		"& [class*='wysiwyg-content']": {
			marginTop: "38px",
			"& iframe": {
				maxWidth: "100%"
			}
		},
		"& [class*='moduleNotAvailable']": {
			"& [class*='-title']": {
				marginBottom: "18px"
			},
			"& [class*='subTitle']": {
				fontSize: "18px"
			},
			"& svg": {
				marginBottom: "18px !important"
			}
		}
	},
	countdownBanner: {
		width: "calc(100% + 20px)",
		boxSizing: "border-box",
		top: "0px",
		left: "0px",
		minHeight: "70px",
		position: "absolute",
		backgroundColor: plumePalette.Blue,
		padding: "29px 35px 26px",
		borderRadius: "0px",
		fontSize: "15px",
		textAlign: "center",
		color: plumePalette.light,
		fontWeight: "500",
		[theme.breakpoints.down("md")]: {
			padding: "18px 35px 15px",
			minHeight: "48px"
		}
	},
	content: {
		marginBottom: "64px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "18px",
			marginBlockEnd: "1.15em",
			color: plumePalette.darkGrey
		}
	},
	fileTitle: {
		fontWeight: 700,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px"
	},
	title:{
		marginBottom: "36px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "24px"
		}
	},
	centeredContainer: {
		maxWidth: "900px",
		paddingBottom: "100px",
		minHeight: "500px"
	},
	metaContent: {
		marginTop: "24px"
	},
	iframeWrapper: {
		marginLeft:"-25px",
		marginRight:"-25px",
		marginTop:"-75px",
		marginBottom: "-25px"
	},
	tabs: {
		marginBottom: "65px",
		position: "relative",
		overflow: "visible",

		"&:after": {
			width: "300vw",
			height: "1px",
			content: "''",
			display: "block",
			borderBottom: "1px solid " + plumePalette.Grey,
			position: "absolute",
			left: "-400px",
			bottom: "0px",
			zIndex: "0"
		}
	},
	tab: {
		color: plumePalette.Grey,
		fontSize: "18px",
		padding: "32px"
	},
	activeTab: {
		color: `${plumePalette.darkGrey} !important`
	},
	webinarNextBtn: {
		float: "right",
		marginLeft: "20px",
		padding: "15px 32px 13px",
		border: "1px solid " + plumePalette.Blue,
		borderRadius: "13px",
		transition: transitionTime,
		"&:hover": {
			backgroundColor: plumePalette.success,
			borderColor: plumePalette.success,
			opacity: "1",
			transition: transitionTime
		}
	},
	video: {
		height: "600px"
	}
}));

export const WebinarPage: FC = () => {
	const {classes} = useStyles();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const {courseSlug, moduleSlug: webinarSlug} = useParams();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const navigate = useNavigate();
	const videoRef = useRef<VideoPlayerRef>(null);
	const [nextModule, setNextModule] = useState<ICourseCurriculumModule>();

	useEffect(() => {
		if(courseSlug && webinarSlug && curriculum)
			setNextModule(getNextLinearModule(curriculum, {slug: webinarSlug, type: "webinar"}))
	}, [courseSlug, webinarSlug, curriculum])

	const handleNextLesson = (): void => {
		if(!curriculum || !webinarSlug)
			return;

		navigate(`/course/${courseSlug}/${nextModule?.type}/${nextModule?.slug}`)
	}

	return (
		<Box className={classes.webinarPage}>
			<WebinarDataProvider courseSlug={courseSlug} webinarSlug={webinarSlug}>
				{({webinar, apiResult}) => {
					const webinarPending = webinar && isBefore(new Date(), webinar.startDateTime);
					const webinarIsActive = !webinarPending && webinar && isAfter(new Date(), webinar.startDateTime) && (!webinar.endDateTime || isBefore(new Date(), webinar.endDateTime));
					const webinarFinished = webinar && webinar.endDateTime && isAfter(new Date(), webinar.endDateTime);

					return <>
						{apiResult.statusCode === 200 && (webinar?.startDateTime) &&<>

							{webinarPending && <>
								<Countdown className={classes.countdownBanner} targetDate={webinar.startDateTime} />
							</>}

							{webinarFinished && <>
								{webinar.videoUrl && <VideoPlayer ref={videoRef} className={classes.video} videoUrl={webinar.videoUrl} />}
								{!webinar?.videoUrl && <ModuleNotAvailable>This webinar is not longer available</ModuleNotAvailable>}
							</>}

							{webinarIsActive && <>
								<Box className={classes.iframeWrapper}>
									<WebinarView webinar={webinar} />
								</Box>
								<Box className={classes.metaContent}>
									{tabSelected === "overview" && <>
									</>}
								</Box>
							</>}


							<CenteredContainer className={classes.centeredContainer}>
								<ModuleTabs
									moduleTabs={[{label: "Overview", value: "overview"}, {label: "Comments", value: "comments"}, {label: "Notes", value: "notes"}]}
									tabSelected={tabSelected}
									onChange={tab => setTab(tab)}
									className={classes.tabs}
								/>

								{tabSelected === "overview" && <>
									<PageTitle className={classes.title} title={webinar?.title} subtitle={webinar?.subtitle} />
									<WYSIWYG className={classes.content}>
										{webinar?.content ?? ""}
									</WYSIWYG>
									{webinar?.files && <>
										<Typography className={classes.fileTitle} variant="h5">Files</Typography>
										<Files
											className={classes.files}
											files={webinar.files}
										/>
									</>}
								</>}

								{tabSelected === "comments" && <CommentsSection courseSlug={courseSlug} moduleType={"webinar"} moduleSlug={webinarSlug}/>}
								{tabSelected === "notes" && <NotesSection courseSlug={courseSlug} moduleType="webinar" moduleSlug={webinarSlug} />}


								<Button className={classes.webinarNextBtn} onClick={() => handleNextLesson()}>
									Next {nextModule?.type}<NavigateNextIcon style={{position: "relative", right: "-10px"}}/>
								</Button>


							</CenteredContainer>
						</>}
						{apiResult.statusCode === 403 || (webinar && (!webinar?.startDateTime))
						&&
							<ModuleNotAvailable>
								{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
							</ModuleNotAvailable>
						}
					</>
				}}
			</WebinarDataProvider>
		</Box>
	);
}