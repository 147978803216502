import {Box} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {LoginForm} from "@plumeuk/shapeshift-common/login";
import {makeStyles} from "tss-react/mui";
import {plumePalette} from "../../constants";

const logo = "/CohdLogo.svg"

const useStyles = makeStyles()((theme) => ({
	loginPage:{
		backgroundColor: plumePalette.light,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		alignItems: "center",
		"& [ class*='MuiInput']:before": {
			border: "none!important"
		},
		flexDirection: "column",
		"& h2": {
			fontSize: "32px"
		},
		"& input[type='text'], & input[type='password']": {
			border: "1px solid " + plumePalette.lightGrey,
			padding: "14px 12px 10px",
			borderRadius: "8px",
			fontSize: "16px"
		},
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.background.paper
		}
	},
	container: {
		marginTop: "15px",
		margin: "auto",
		display: "flex",
		width: "90%",
		maxWidth: "550px",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	imgContainer: {
		margin: "40px 0",
		textAlign: "center",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			margin: "30px 0"
		},
		"& img": {
			[theme.breakpoints.down("sm")]: {
				width: "149px"
			}
		}
	},
	loginForm: {
		marginTop: "15px"
	}
}));

export const LoginPage: FC = () => {
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if(authenticated) navigate("/my-learning")
	}, [authenticated])

	return (
		<Box className={classes.loginPage}>
			<Box className={classes.container}>
				<LoginForm
					className={classes.loginForm}
					title="Login to Cohd LMS"
					onLogin={() => navigate("my-learning")}
				/>
				<div className={classes.imgContainer}>
					<img src={logo} />
				</div>
			</Box>
		</Box>
	);
}