import {Box, BoxProps} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {MobileHeader, NavBar, SideMenu} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import {plumePalette, sideMenuWidth, toolBarHeight, transitionTime} from "../../constants";
import {ModuleNavBar} from "../moduleNavBar";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";

const downloadIcon = "/download.svg";
const logo = "/CohdLogo.svg";

interface ICustomProps {
	children: React.ReactNode,
	contentWidth?: string,
	sideBarContent?: JSX.Element,
	disableSideBar?: boolean
}

interface IStyleProps {
	sideMenuOpen: boolean,
	hasNavBar: boolean,
	contentWidth?: string,
	pageIsMyLearning?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, hasNavBar, contentWidth, pageIsMyLearning}) => ({
	pageBase: {
		height: "100%",
		"& [class*='iconContainer']": {
			width: "57px",
			height: "57px",
			fontSize: "18px",
			fontWeight: "600",
			letterSpacing: "2px",
			margin: "16px 20px",
			padding: "3px 0px 0px 2px",
			lineHeight: "1",
			transition: transitionTime,
			[theme.breakpoints.down("md")]: {
				width: "46px",
				height: "46px",
				fontSize: "16px"
			},
			"&:hover": {
				border: 0,
				outline: 0,
				opacity: "0.65",
				transition: transitionTime
			}
		},
		"& svg[class*='menuIcon']": {
			marginRight: "8px",
			display: pageIsMyLearning ? "none" : "block",
			color: plumePalette.Grey,
			border: "0px solid",
			borderRadius: "100%",
			width: "1.35em",
			height: "1.35em",
			[theme.breakpoints.down("md")]: {
				display: "flex"
			}
		},
		"& [class*='moduleTitle-title']": {
			marginBottom: "12px"
		},
		"& [class*='files']": {
			"& [class*='file']": {
				backgroundColor: plumePalette.light,
				"& [class*='description']": {
					paddingTop: "15px"
				}
			},
			"& [class*='contentContainer']": {
				marginLeft: "18px",
				scrollbarGutter: "stable",
				"& [class*='description']": {
					fontSize: "16px"
				}
			},
			"& [class*='fileIcon']": {
				borderRadius: "22px",
				margin: "15px 10px",
				padding: "17px 17px 14px 17px",
				backgroundColor: plumePalette.Blue,
				"& svg": {
					width: "25px",
					height: "25px"
				}
			},
			"& [class*='downloadButton']": {
				padding: "19px 0px 17px 30px",
				margin: "0px 10px",
				transition: transitionTime,
				"&:hover": {
					opacity: 0.7,
					transition: transitionTime
				},
				"& svg": {
					background: `url(${downloadIcon}) center no-repeat`,
					backgroundSize: "contain",
					fill: "transparent"
				},
				"& p": {
					fontWeight: "500",
					fontSize: "16px",
					marginTop: "2px"
				},
				[theme.breakpoints.down("sm")]: {
					paddingLeft: "0px",
					paddingRight: "0px",
					marginLeft: "-20px",
					marginRight: "-20px",
					borderTop: "1px solid " + plumePalette.Grey
				}
			}
		},
		"& [class*='mobileHeader']": {
			"& [class*='-profile']": {
				backgroundColor: plumePalette.light,
				[theme.breakpoints.down("md")]: {
					marginBottom: "24px"
				}
			},
			"& [class*='bellIconContainer']": {
				display: "none"
			}
		}
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: toolBarHeight
	},
	contentContainer: {
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? sideMenuWidth : "0px",
		boxSizing: "border-box",
		overflowY: "auto",
		padding: "25px",
		top: hasNavBar ? toolBarHeight : 0,
		height: hasNavBar ? `calc(100% - ${toolBarHeight})` : "100%",
		scrollbarGutter: "stable",
		[theme.breakpoints.down("md")]: {
			left: 0,
			width: "100%"
		}
	},
	sideMenu: {
		"& [class*='drawer']": {
			borderRight: "1px solid " + plumePalette.Grey + " !important"
		}
	},
	content: {
		[theme.breakpoints.up("sm")]: {
			height: "100%"
		},
		minHeight: "100%",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: contentWidth ?? "1000px",
		margin: "auto"

	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "My Learning" | "Library";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"My Learning": "/my-learning",
	"Library": "/library"
}

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, contentWidth, children, className, ...props}) => {
	const mq = useMq();
	const pageIsMyLearning = useMatch("/my-learning");
	const location = useLocation();
	const [sideMenuOpen, setSideMenuOpen] = useState(!disableSideBar && mq.desktop);
	const {classes, cx} = useStyles({sideMenuOpen, hasNavBar: !mq.mobile || !pageIsMyLearning, contentWidth, pageIsMyLearning: !!pageIsMyLearning});
	const navigate = useNavigate();
	const homePath = "/my-learning";

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if (location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	const handlePageSelect = (page: pages): void => {
		navigate(routesMap[page]);
	}

	const handleLogoClick = (): void => {
		navigate(homePath);
	}

	const handleContentClick = (): void => {
		if (!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			{!mq.mobile &&
				<NavBar<pages>
					tabs={["My Learning", "Library"]}
					onLogoClick={handleLogoClick}
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
					onPageSelect={handlePageSelect}
					pageSelected={pageSelected}
					logoPath={logo}
					disableSideBar={disableSideBar}
					editUserDetailsForm={EditUserDetailsForm}
				/>}
			{mq.mobile && !pageIsMyLearning &&
				<ModuleNavBar
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
				/>}
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
				width={sideMenuWidth}
			>
				{sideBarContent}
			</SideMenu>
			<Box className={classes.contentContainer} onClick={handleContentClick}>
				{mq.mobile && pageIsMyLearning && <MobileHeader editUserDetailsForm={EditUserDetailsForm}/>}
				<Box className={classes.content}>
					{children}
				</Box>
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
