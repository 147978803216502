import {FC, useCallback, useEffect, useState} from "react";
import {Box, BoxProps, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {IMarkedQuizAnswers, IQuiz, IQuizAnswer, IQuizQuestion, ISurvey, ISurveyAnswer, ISurveyQuestion} from "@plumeuk/shapeshift-types";
import {QuizStartSummary} from "../QuizPage/QuizStartSummary/QuizStartSummary";
import {QuizQuestions} from "../QuizPage/QuizQuestions/QuizQuestions";
import {useParams} from "react-router-dom";
import {useApi} from "@plumeuk/shapeshift-identity";
import {QuizResult} from "../QuizPage/QuizQuestions/QuizResult/QuizResult";
import {SurveyStartSummary} from "../SurveyPage/SurveyStartSummary/SurveyStartSummary";
import {SurveyQuestions} from "../SurveyPage/SurveyQuestions/SurveyQuestions";
import {SurveyCompletion} from "../SurveyPage/SurveyCompletion";
import {Check} from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
	miniSurvey: {
		"& [class*='sendToInstructorCheckBoxContainer']": {
			display: "none"
		},
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	quizStartSummary: {
		padding: 0,
		maxWidth: "none",
		textAlign: "left",
		"& [class*='centerTile']": {
			padding: 0
		}
	},
	quizResult: {
		"& [class*='nextBtn']": {
			display: "none"
		},
		"& [class*='buttonContainer'] .nextModuleBtn, [class*='buttonContainer'] .nextModuleBtn, [class*='buttonContainer'] .retakeBtn": {
			display: "none"
		}
	}
}));

interface IPropsCustom {
	survey: ISurvey,
	autoStart?: boolean,
	onComplete: (result: ISurveyAnswer[]) => void,
	enableNotifyMyInstructor?: boolean
}

type IProps = IPropsCustom & BoxProps;

export const MiniSurvey: FC<IProps> = ({survey, onComplete, autoStart, className, ...props}) => {
	const {classes, cx} = useStyles();
	const {courseSlug, moduleSlug: lessonSlug} = useParams();
	const [navState, setNavState] = useState<"summary" | "active" >(autoStart ? "active" : "summary");
	const [apiResponse, submitSurveyResponse] = useApi<ISurveyAnswer[]>()
	const [prevResponse] = useApi<ISurveyAnswer[]>(`/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/surveys/${survey.slug}/latestResponse`)
	const [surveyResponse, setSurveyResponse] = useState<ISurveyAnswer[]>([]);
	const [{data: surveyQuestions}] = useApi<ISurveyQuestion[]>(`/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/surveys/${survey.slug}/questions`, [])
	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		setSubmitted(false)
	}, [survey])

	const markAnswersActionOverride = useCallback((e: {sendToInstructor?: boolean, answers: ISurveyAnswer[]}) => {
		setSubmitted(true);
		submitSurveyResponse({
			url: `/api/strapi-plugin-shapeshift-lms/${courseSlug}/lessons/${lessonSlug}/submitSurvey/${survey.slug}`,
			data: e.answers,
			method: "POST"
		})
		return true
	}, [])

	useEffect(() => apiResponse.data && setSurveyResponse(apiResponse.data), [apiResponse])
	useEffect(() => prevResponse.data && setSurveyResponse(prevResponse.data), [prevResponse])

	useEffect(() => {
		if(surveyResponse.length > 0){
			onComplete(surveyResponse);
		}
	}, [surveyResponse])

	return (
		<Box className={cx(classes.miniSurvey, className)} {...props}>
			{navState === "summary" && <SurveyStartSummary className={classes.quizStartSummary} survey={survey} onContinueActionOverride={() => {setNavState("active"); return true}} />}
			{navState === "active" && (surveyQuestions ?? [])?.length > 0 && <SurveyQuestions surveyResponseDataOverride={surveyResponse} surveySlug={survey.slug} submitResponseActionOverride={markAnswersActionOverride} surveyQuestionDataOverride={surveyQuestions}/>}
			{/* {navState === "complete" && <SurveyCompletion survey={survey} data={surveyResponse}/>} */}
			{/* {surveyResponse && <Button onClick={() => setNavState("active")}>Retake</Button>} */}
			{(submitted && surveyResponse.length > 0) && <Box sx={{float: "right", display: "flex", gap: "5px"}}><Check/><Typography>Thank you for submitting </Typography></Box>}
		</Box>
	);
}