import {SideMenuProfileGamification} from "@plumeuk/shapeshift-common/pageBase";
import {GroupTable} from "@plumeuk/shapeshift-common/groups";
import {CenteredContainer} from "../../components/pageBase/centeredContainer";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles()((_theme) => ({
	sideMenu: {
		color: "black"
	}
}));


export const MyGroupsPage: React.FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();

	return (
		<PageBase sideBarContent={<SideMenuProfileGamification className={classes.sideMenu} />}>
			<CenteredContainer>
				<GroupTable onGoToGroupAction={(slug) => navigate(`/my-groups/${slug}`)}/>
			</CenteredContainer>
		</PageBase>
	);
}