import {QuizDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {QuizRouter} from "./QuizRouter";
import {plumePalette} from "../../../constants";

const useStyles = makeStyles()((_theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center",
		"& [class*='questionOption']": {
			padding: "10px 10px 8px",
			"& [class*='optionId']": {
				padding: "14px 20px 8px 12px",
				borderRight: "1px solid" + plumePalette.lightGrey
			},
			"& [class*='childrenContainer']": {
				padding: "14px 20px 10px"
			}
		}
	}
}));

export const QuizPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: quizSlug} = useParams();
	return (
		<CenteredContainer className={classes.centeredContainer}>
			<QuizDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
				{({quiz}) => <QuizRouter quiz={quiz}/>}
			</QuizDataProvider>
		</CenteredContainer>
	);
}