import {Box, Typography} from "@mui/material";
import {CourseTable} from "@plumeuk/shapeshift-common/courses";
import {ModuleTabs, ModuleTab, UpcomingModules, UpcomingWebinarSpotlight} from "@plumeuk/shapeshift-common/module";
import {MemberedGroupUserList} from "@plumeuk/shapeshift-common/groups";
import {useState, useCallback} from "react";
import {SideMenuProfileModuleProgression} from "../../components/pageBase/sideMenuProfileModuleProgression/sideMenuProfileModuleProgression"
import {useNavigate} from "react-router-dom";
import {CenteredContainer} from "../../components/pageBase/centeredContainer";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {plumePalette, transitionTime} from "../../constants";
import {CoursesDataProvider, MemberedGroupsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {IModuleUpcoming} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	sectionTitle: {
		background: plumePalette.light,
		borderRadius: "8px",
		padding: "16px 24px 12px",
		fontSize: "22px"
	},
	tabs: {
		marginBottom: "42px",
		"& [class*='-tab']": {
			color: "#A8A8A8",
			fontSize: "16px",
			minWidth: "50%",
			width: "50%",
			textTransform: "uppercase",
			borderBottom: "2px solid " + plumePalette.lightGrey,
			[theme.breakpoints.down("md")]: {
				fontSize: "14px",
				minHeight: "36px"
			}
		}
	},
	courseTable: {
		padding: "0px 0px",
		margin: "0px auto 24px",
		position: "relative",

		"& [class*='topContainer']": {
			position: "absolute",
			top: "-78px",
			right: "0px",
			[theme.breakpoints.down("md")]: {
				position: "relative",
				top: "0px"
			},
			"& h4": {
				display: "none !important"
			},
			"& [class*='tabs']": {

				"& [class*='tab']": {
					padding: "0px 6px",
					fontSize: "12px",
					borderBottom: "0px solid !important",
					opacity: "0.3",
					whiteSpace: "nowrap",
					[theme.breakpoints.down("md")]: {
						width: "auto"
					},
					"&[aria-selected='true']": {
						opacity: "1"
					}
				},
				"& [class*='indicator']": {
					display: "none"
				}
			},
			"& [class*='searchBox']": {
				display: "none"
			}
		},
		"& [class*='courseItem']": {
			transition: "0.35s",
			[theme.breakpoints.down("md")]: {
				paddingBottom: "12px"
			},
			"& [class*='imageContainer']": {
				display: "none"
			},
			"& [class*='infoContainer']": {
				padding: "0px 40px 16px 0px",
				"& [class*='body1']": {
					display: "none"
				},
				"& h4": {
					marginBottom: "4px",
					[theme.breakpoints.down("md")]: {
						marginBottom: "0px",
						fontSize: "22px"
					}
				}
			},
			"& [class*='certificateBtn']": {
				marginTop: "12px"
			},
			"& [class*='buttonContainer']": {
				padding: "0px 0px 16px",
				margin: 0
			},
			"& button ": {
				"& svg": {
					display: "none"
				},
				padding: "16px 24px 14px",
				minWidth: "120px",
				marginTop: "0",
				[theme.breakpoints.down("md")]: {
					padding: "12px 21px 10px",
					marginBottom: "0px"
				}
			},
			"& [class*='loadingContainer']": {
				padding: "0px 0 12px",
				borderTop: "0px solid" + plumePalette.Grey,
				[theme.breakpoints.down("md")]: {
					paddingBottom: "8px"
				}
			},
			"& [class*='loadingBar']": {
				backgroundColor: plumePalette.Grey + "20",
				[theme.breakpoints.down("md")]: {
					marginBottom: "8px"
				}
			},
			"&:hover": {
				opacity: "0.9",
				cursor: "pointer",
				transition: "0.35s"
			}
		},
		"& [class*='infoContainer'] p": {
			fontWeight: 500
		},
		"& [class*='noCoursesForFiltersBody']": {
			marginTop: "0px"
		}
	},
	groupUserList: {
		"& [class*='TableRow']": {
			borderBottom: "1px solid " + plumePalette.Grey,
			"&:nth-last-child(1)": {
				borderBottom: "0px"
			}
		},
		"& [class*='TableCell']": {
			fontSize: "17px",
			padding: "2px 24px 0px",
			borderBottom: "0px",
			"& [class*='iconContainer']": {
				fontSize: "16px",
				width: "50px",
				height: "50px",
				margin: "16px 14px 18px 16px",
				"&:hover": {
					cursor: "auto",
					opacity: "1"
				}
			},
			"&:nth-child(1)": {
				paddingLeft: "24px",
				paddingRight: "10px",
				maxWidth: "40px",
				width: "40px"
			},
			"&:nth-child(2)": {
				paddingLeft: "0px",
				maxWidth: "40px"
			},
			"&:nth-last-child(1)": {
				paddingRight: "40px",
				width: "100%"
			}
		},
		"& [class*='groupMemberName']": {
			"& span": {
				fontSize: "16px"
			}
		}
	},
	profileTitleContainer: {
		backgroundColor: plumePalette.light,
		width: "calc(100vw - 390px)",
		marginLeft: "calc(0px - 50vw + 195px + 50%)",
		marginBottom: "24px",
		borderTop: "1px solid " + plumePalette.Grey,
		[theme.breakpoints.down("md")]: {
			width: "calc(100% + 50px)",
			marginLeft: "-25px"
		}
	},
	profileTitleInner: {
		maxWidth: "1000px",
		padding: "17px 38px 14px",
		margin: "0px auto",
		fontWeight: "700"
	},
	upcomingModules: {
		transition: transitionTime,
		"& [class*='upcomingModuleItem']:hover": {
			cursor: "pointer",
			opacity: "0.7",
			transition: transitionTime
		},
		"& [class*='upcomingModuleIconCircle']": {
			opacity: "0.15"
		}
	},
	upcomingContainer: {
		display: "grid",
		gridTemplateColumns: "2fr 1fr",
		gridColumnGap: "24px",
		[theme.breakpoints.down("md")]: {
			gridTemplateColumns: "1fr",
			gridRowGap: "24px"
		}
	},
	profileTitleInnerContainer: {
		position: "relative"
	},
	profileTitleInnerLabel: {
		position: "absolute",
		right: 0,
		top: 0,
		fontSize: "15px",
		marginTop: "1px",
		opacity: "0.75"
	},
	upcomingWebinarSpotlight: {
		borderLeft: "solid 1px " + plumePalette.black,
		marginLeft: "20px",
		"& [class*='upcomingWebinarSpotlightButton']": {
			borderRadius: "6px",
			padding: "20px"
		},
		"& [class*='upcomingWebinarSpotlightDescription']": {
			color: plumePalette.midGrey
		}
	}
}));

export const MyLearningPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();
	const LEARNING_TABS: { label: string; value: ModuleTab }[] = [
		{
			label: "Dashboard",
			value: "overview"
		},
		{
			label: "My Group",
			value: "comments"
		}
	];

	const [tabSelected, setTab] = useState<ModuleTab>("overview");

	const handleGoToModule = useCallback((module: IModuleUpcoming): void => {
		navigate(`/course/${module.courseSlug}/${module.type}/${module.moduleSlug}`);
	}, [navigate]);

	const handleGoToWebinar = useCallback((courseSlug: string, moduleSlug: string): void => {
		navigate(`/course/${courseSlug}/webinar/${moduleSlug}`);
	}, [navigate]);

	return (
		<PageBase sideBarContent={<SideMenuProfileModuleProgression />}>
			<CenteredContainer>
				<ModuleTabs tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} moduleTabs={LEARNING_TABS} />
				{tabSelected === "overview" &&
					<Box>
						<Box className={classes.profileTitleContainer}><Typography className={classes.profileTitleInner} variant="h5">Programs</Typography></Box>
						<CourseTable
							className={classes.courseTable}
							onGoToCourseAction={courseId => { navigate("/course/" + courseId) }}
							onGoToCertificateAction={courseId => { navigate(`/course/${courseId}/complete`) }}
						/>
						<Box className={classes.profileTitleContainer}><Typography className={classes.profileTitleInner} variant="h5">Upcoming tasks</Typography></Box>
						<CoursesDataProvider>
							{({courses}) => (
								courses && courses.length > 0 ? (
									<Box className={classes.upcomingContainer}>
										<UpcomingModules
											courseSlug={courses?.[0].slug}
											className={classes.upcomingModules}
											onGoToModuleAction={handleGoToModule}
										/>
										<UpcomingWebinarSpotlight
											className={classes.upcomingWebinarSpotlight}
											courseSlug={courses?.[0].slug}
											Icon={<img src="/calendar.svg" />}
											onGotToWebinarAction={handleGoToWebinar}
										/>
									</Box>
								) : (
									<Typography>You are not enrolled in any courses.</Typography>
								)
							)}
						</CoursesDataProvider>
					</Box>
				}
				{tabSelected === "comments" &&
					<Box>
						<MemberedGroupsDataProvider>
							{({groups}) => (
								<Box>
									<Box className={classes.profileTitleContainer}>
										<Typography className={classes.profileTitleInner} variant="h5">
											<Box className={classes.profileTitleInnerContainer}>
												{groups?.[0]?.title}
												<Typography className={classes.profileTitleInnerLabel} component="span">
													{groups ? groups?.[0]?.members : 0} members
												</Typography>
											</Box>
										</Typography>
									</Box>
									<MemberedGroupUserList
										className={classes.groupUserList}
										group={groups?.[0]}
									/>
								</Box>
							)}
						</MemberedGroupsDataProvider>
					</Box>
				}
			</CenteredContainer>
		</PageBase>
	);
}