import {Box} from "@mui/material";
import {FC, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {SideMenuProfile, SideMenuModuleProgression} from "@plumeuk/shapeshift-common/pageBase";
import {CoursesDataProvider} from "@plumeuk/shapeshift-common/providers";
import {plumePalette, transitionTime} from "../../../constants";
import EditUserDetailsForm from "../../editUserDetailsForm/editUserDetailsForm";
import {moduleTypeSlim} from "@plumeuk/shapeshift-types/ICourse";

const useStyles = makeStyles()(() => ({
	container: {
		"& [class*='navBarProfile']": {
			borderBottom: "1px solid " + plumePalette.Grey,
			paddingBottom: "32px"
		},
		"& [class*='userIcon']": {
			marginTop: "75px",
			fontWeight: "600",
			letterSpacing: "3px",
			padding: "4px 0px 0px 3px",
			lineHeight: "0.5"
		},
		"& [class*='userName']": {
			color: plumePalette.darkGrey,
			marginBottom: "12px",
			fontSize: "24px",
			fontWeight: "600"
		},
		"& [class*='outlineButton']": {
			paddingLeft: "32px",
			paddingRight: "32px",
			"& svg path": {
				fill: plumePalette.Blue,
				transition: transitionTime
			},
			"&:hover svg path": {
				fill: plumePalette.background,
				transition: transitionTime
			}
		}
	}
}));

export const SideMenuProfileModuleProgression: FC = () => {

	const {classes} = useStyles()
	const navigate = useNavigate();

	const handleGoToModule = useCallback((courseSlug?: string, moduleType?: moduleTypeSlim, moduleSlug?: string): void => {
		navigate("/course/"+courseSlug+"/"+moduleType+"/"+moduleSlug);
	},[navigate]);

	return (
		<Box className={classes.container}>
			<SideMenuProfile editUserDetailsForm={EditUserDetailsForm} />
			<CoursesDataProvider>
				{({courses}) => (
					<SideMenuModuleProgression
						onGoToModuleLinkAction={handleGoToModule}
						course={courses?.[0]}
					/>
				)}
			</CoursesDataProvider>
		</Box>
	)
}