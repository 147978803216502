import {Box, BoxProps, LinearProgress} from "@mui/material";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";
import {FC, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {QuizResultBreakdown} from "./QuizResultBreakdown";
import {QuizResultSummary} from "./QuizResultSummary";
import {MarkedQuizResultDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useParams} from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
	quizResult: {
		textAlign: "left",
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px"
		},
		"& [class*='markedIconContainer']": {
			"& svg": {
				fontSize: "24px",
				marginTop: "-3px"
			}
		},
		"& [class*='-title']": {
			marginBottom: "12px !important"
		},
		"& [class*='-subTitle']": {
			marginBottom: "62px !important"
		},
		"& [class*='markedQuestionSummary']": {
			"& [class*='container']": {
				padding: "40px 20px 32px"
			}
		},
		"& [class*='errorClasses']": {
			"& svg": {
				fontSize: "24px",
				marginTop: "0px"
			}
		}
	},
	label: {
		color: "#A8A8A8"
	},
	title: {
		margin: "60px 0 400px 0"
	},
	subTitle: {
		marginBottom: "40px",
		color: "#A8A8A8"
	}
}));

interface IProps extends BoxProps {
	quiz?: IQuiz,
	data?: IMarkedQuizAnswers
}

export const QuizResult: FC<IProps> = ({quiz, data, className, ...props}) => {
	const {classes, cx} = useStyles();
	const [checkAnswers, setCheckAnswers] = useState(false);
	const {courseSlug, resultId, moduleSlug: quizSlug} = useParams();

	useEffect(() => {
		setCheckAnswers(false);
	}, [quiz]);

	return (
		<MarkedQuizResultDataProvider data={data} courseSlug={courseSlug} quizSlug={quizSlug} resultId={resultId}>
			{({markedQuizResult,apiResult}) =>
				<Box className={cx(classes.quizResult, className)} {...props}>
					{apiResult.isLoading && <LinearProgress />}
					{markedQuizResult && (checkAnswers || !markedQuizResult.pass
						? <QuizResultBreakdown quiz={quiz} markedAnswers={markedQuizResult} />
						: <QuizResultSummary quiz={quiz} markedAnswers={markedQuizResult} onCheckAnswersAction={() => setCheckAnswers(true)}/>
					)}
				</Box>
			}
		</MarkedQuizResultDataProvider>
	);
}