import {Box, Typography} from "@mui/material";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {ProductRecommended, ProductSummaryTile} from "@plumeuk/shapeshift-common/product";
import {LibraryItemTags} from "@plumeuk/shapeshift-common/library";
import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {LibraryCourseDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {PageBase} from "../../components/pageBase/pageBase";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";

const useStyles = makeStyles()((theme) => ({
	courseLibraryProductPage: {
		paddingTop: "40px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	topContainer: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column"
		}
	},
	summaryTile: {
		marginRight: "40px",
		flexShrink: 0,
		marginBottom: "30px",
		width: "300px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	title:{
		marginBottom: "25px"
	},
	container: {
	},
	content: {
		margin: "40px 0"
	},
	tagContainer: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse",
			height: "70px",
			justifyContent: "space-between"
		}
	},
	videoPlayer: {
		margin: "40px 0"
	},
	recommended: {
		marginTop: "30px"
	}
}));

export const CourseLibraryProductPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const mq = useMq();

	return (
		<PageBase contentWidth="1300px" disableSideBar>
			<LibraryCourseDataProvider slug={courseSlug}>
				{({course}) => (
					<Box className={classes.courseLibraryProductPage}>
						<Box className={classes.topContainer}>
							<ProductSummaryTile
								libraryItem={course ? {...course, __type: "plugin::strapi-plugin-shapeshift-cms.course"} : undefined}
								className={classes.summaryTile}
								onEnrollAction={() => navigate("/my-learning")}
							/>
							<Box className={classes.container}>
								<PageTitle className={classes.title} title={course?.title} subtitle={course?.description} />
								<Box className={classes.tagContainer}>
									<LibraryItemTags item={course ? {...course, __type: "plugin::strapi-plugin-shapeshift-cms.course"} : undefined}/>
									<Typography>Created by {course?.author}</Typography>
								</Box>
								{course?.videoUrl && <VideoPlayer
									className={classes.videoPlayer}
									url={course.videoUrl}
									controls={true}
									height={mq.mobile ? "250px" : "550px"}
								/>}
								<WYSIWYG className={classes.content}>{course?.content ?? ""}</WYSIWYG>
							</Box>
						</Box>
						<ProductRecommended
							className={classes.recommended}
							onGoToItemAction={slug => navigate(`/library/${slug}`)}
						/>
					</Box>
				)}
			</LibraryCourseDataProvider>
		</PageBase>
	);
}